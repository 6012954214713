<template>
  <div>
    <label for="fileInputLabel" slot="upload-label" class="image-select" @click="getFile">
      <AppUserAvatar class="mb-4" :user="user" :size="128"></AppUserAvatar>
      <v-icon large class="btn-camera" color="primary lighten-1">mdi-camera</v-icon>
    </label>
    <input ref="FileInput" type="file" style="display: none" @change="onFileSelect" />
    <v-dialog v-model="dialog" max-width="320" @click:outside="resetImage" scrollable>
      <v-card class="py-2">
        <v-card-text class="py-2">
          <VueCropper
            v-show="selectedFile"
            ref="cropper"
            :src="selectedFile"
            :zoomOnWheel="false"
            :aspectRatio="1"
            alt="Source Image"
          ></VueCropper>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-btn class="primary ml-2" @click="setImage(), (dialog = false)">Crop &amp; Save</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" class="mr-2" text @click="resetImage">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <image-uploader
      ref="ImageUpload"
      :maxWidth="128"
      :quality="0.9"
      :preview="false"
      :className="['fileinput', { 'fileinput--loaded': hasImage }]"
      capture="environment"
      :debug="1"
      :autoRotate="false"
      outputFormat="verbose"
      accept="image/*"
      @input="setImage"
    >
    </image-uploader>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import ImageUploader from "vue-image-upload-resize";
import VueCropper from "vue-cropperjs";
import Helpers from "@/mixins/helpers";
import AppUserAvatar from "@/components/AppUserAvatar.vue";
import { UserService, AssetService, TokenService } from "@/services";
import idb from "@/api/idb";

export default {
  name: "TheImageUploader",
  components: {
    ImageUploader,
    VueCropper,
    AppUserAvatar,
  },
  props: ["image_name"],
  mixins: [Helpers],
  computed: {
    ...mapGetters("user", ["user"]),
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.$refs.FileInput.value = null;
      }
    },
  },
  data() {
    return {
      useCrop: true,
      hasImage: false,
      S3Client: null,
      selectedFile: "",
      origFile: "",
      image: "",
      dialog: false,
    };
  },
  methods: {
    ...mapActions("settings", ["updateUserProfile"]),
    ...mapActions("user", ["setUser"]),
    ...mapActions("auth", ["loadAccessToken"]),
    ...mapActions("loader", ["pending", "done"]),
    getFile() {
      if (this.useCrop) {
        this.$refs.FileInput.click();
      } else {
        document.getElementsByClassName("fileinput")[0].click();
      }
    },

    onFileSelect(e) {
      const file = e.target.files[0];
      if (!file) {
        return;
      }
      if (!file.type.includes("image")) {
        this.$snackbar.showMessage({
          content: this.$t("c_snackbar.t_images_only_please"),
          color: "warning",
          timeout: "",
        });
        return;
      }
      if (file.size > 10e6) {
        this.$snackbar.showMessage({
          content: this.$t("c_snackbar.t_image_size_not_exceed_10MB"),
          color: "error",
          timeout: "",
        });
        return;
      }
      this.origFile = file;
      if (typeof FileReader === "function") {
        this.dialog = true;
        const reader = new FileReader();
        reader.onload = (event) => {
          this.selectedFile = event.target.result;
          this.$refs.cropper.replace(this.selectedFile);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },

    async resizeBase64Image(base64, width, height) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = base64;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          const resizedBase64 = canvas.toDataURL();
          resolve(resizedBase64);
        };
        img.onerror = reject;
      });
    },

    async getBase64ImageDimensions(base64) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = base64;
        img.onload = () => {
          resolve({
            width: img.width,
            height: img.height,
          });
        };
        img.onerror = reject;
      });
    },

    async imageUpload(base64) {
      const dimensions = await this.getBase64ImageDimensions(base64);
      let resizedBase64 = base64;
      if (dimensions.width > 128) {
        resizedBase64 = await this.resizeBase64Image(base64, 128, 128);
      }

      const base64Data = new Buffer.from(resizedBase64.replace(/^data:image\/\w+;base64,/, ""), "base64");

      this.pending();

      const payload = {
        name: this.origFile.name,
        description: "",
        originalFileName: this.origFile.name,
        mimeType: this.origFile.type,
        cacheable: true,
        permitCompany: true,
      };

      AssetService.uploadAssetBegin(payload)
        .then((a) => {
          let params = {
            method: "PUT",
            headers: {
              "Content-Type": this.origFile.type,
              "Content-Encoding": "base64",
            },
            body: base64Data,
          };
          fetch(a.data.uploadUrl, params).then((r) => {
            if (r.ok) {
              fetch(resizedBase64)
                .then((s3) => s3.blob())
                .then((imageBlob) => {
                  AssetService.uploadAssetComplete({ assetId: a.data.assetId }).then(() => {
                    UserService.linkProfileImage({ assetId: a.data.assetId }).then(() => {
                      UserService.refreshToken().then(() => {
                        if (TokenService.getToken()) {
                          this.loadAccessToken();
                          this.setUser();
                        }
                      });
                      idb.putS3Item(a.data.assetGuid, imageBlob);
                    });
                  });
                });
            } else {
              AssetService.deleteAsset({ assetId: a.data.assetId });
            }
          });
        })
        .finally(() => {
          this.done();
        });
    },

    setImage(output = null) {
      this.hasImage = true;
      let useImage = this.useCrop ? this.$refs.cropper.getCroppedCanvas().toDataURL() : output?.dataUrl;
      if (useImage) {
        this.imageUpload(useImage);
      }
    },

    resetImage() {
      this.dialog = false;
      this.selectedFile = null;
      this.origFile = null;
    },
  },
};
</script>
<style lang="scss">
#fileInput {
  display: none;
}
.img-preview {
  max-width: 120px;
  max-height: 120px;
}
.btn-camera {
  margin: 0 0 -85px -35px;
}
.image-select {
  &:hover {
    .btn-camera {
      color: #fff !important;
      cursor: pointer;
    }
  }
}
.v-application .v-avatar.update-avatar {
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.6) !important;
  }
}
.cropper-container {
  direction: ltr;
  font-size: 0;
  line-height: 0;
  position: relative;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cropper-container img {
  image-orientation: 0deg;
  display: block;
  height: 100%;
  max-height: none !important;
  max-width: none !important;
  min-height: 0 !important;
  min-width: 0 !important;
  width: 100%;
}
.cropper-canvas,
.cropper-crop-box,
.cropper-drag-box,
.cropper-modal,
.cropper-wrap-box {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.cropper-canvas,
.cropper-wrap-box {
  overflow: hidden;
}
.cropper-drag-box {
  background-color: #fff;
  opacity: 0;
}
.cropper-modal {
  background-color: #000;
  opacity: 0.5;
}
.cropper-view-box {
  display: block;
  height: 100%;
  outline: 1px solid #39f;
  outline-color: rgba(51, 153, 255, 0.75);
  overflow: hidden;
  width: 100%;
  border-radius: 50%;
}
.cropper-dashed {
  border: 0 dashed #eee;
  display: block;
  opacity: 0.5;
  position: absolute;
}
.cropper-dashed.dashed-h {
  border-bottom-width: 1px;
  border-top-width: 1px;
  height: 33.33333%;
  left: 0;
  top: 33.33333%;
  width: 100%;
}
.cropper-dashed.dashed-v {
  border-left-width: 1px;
  border-right-width: 1px;
  height: 100%;
  left: 33.33333%;
  top: 0;
  width: 33.33333%;
}
.cropper-center {
  display: block;
  height: 0;
  left: 50%;
  opacity: 0.75;
  position: absolute;
  top: 50%;
  width: 0;
}
.cropper-center:after,
.cropper-center:before {
  background-color: #eee;
  content: " ";
  display: block;
  position: absolute;
}
.cropper-center:before {
  height: 1px;
  left: -3px;
  top: 0;
  width: 7px;
}
.cropper-center:after {
  height: 7px;
  left: 0;
  top: -3px;
  width: 1px;
}
.cropper-face,
.cropper-line,
.cropper-point {
  display: block;
  height: 100%;
  opacity: 0.1;
  position: absolute;
  width: 100%;
}
.cropper-face {
  background-color: #fff;
  left: 0;
  top: 0;
}
.cropper-line {
  background-color: #39f;
}
.cropper-line.line-e {
  cursor: ew-resize;
  right: -3px;
  top: 0;
  width: 5px;
}
.cropper-line.line-n {
  cursor: ns-resize;
  height: 5px;
  left: 0;
  top: -3px;
}
.cropper-line.line-w {
  cursor: ew-resize;
  left: -3px;
  top: 0;
  width: 5px;
}
.cropper-line.line-s {
  bottom: -3px;
  cursor: ns-resize;
  height: 5px;
  left: 0;
}
.cropper-point {
  background-color: #39f;
  height: 5px;
  opacity: 0.75;
  width: 5px;
}
.cropper-point.point-e {
  cursor: ew-resize;
  margin-top: -3px;
  right: -3px;
  top: 50%;
}
.cropper-point.point-n {
  cursor: ns-resize;
  left: 50%;
  margin-left: -3px;
  top: -3px;
}
.cropper-point.point-w {
  cursor: ew-resize;
  left: -3px;
  margin-top: -3px;
  top: 50%;
}
.cropper-point.point-s {
  bottom: -3px;
  cursor: s-resize;
  left: 50%;
  margin-left: -3px;
}
.cropper-point.point-ne {
  cursor: nesw-resize;
  right: -3px;
  top: -3px;
}
.cropper-point.point-nw {
  cursor: nwse-resize;
  left: -3px;
  top: -3px;
}
.cropper-point.point-sw {
  bottom: -3px;
  cursor: nesw-resize;
  left: -3px;
}
.cropper-point.point-se {
  bottom: -3px;
  cursor: nwse-resize;
  height: 20px;
  opacity: 1;
  right: -3px;
  width: 20px;
}
@media (min-width: 768px) {
  .cropper-point.point-se {
    height: 15px;
    width: 15px;
  }
}
@media (min-width: 992px) {
  .cropper-point.point-se {
    height: 10px;
    width: 10px;
  }
}
@media (min-width: 1200px) {
  .cropper-point.point-se {
    height: 5px;
    opacity: 0.75;
    width: 5px;
  }
}
.cropper-point.point-se:before {
  background-color: #39f;
  bottom: -50%;
  content: " ";
  display: block;
  height: 200%;
  opacity: 0;
  position: absolute;
  right: -50%;
  width: 200%;
}
.cropper-invisible {
  opacity: 0;
}
.cropper-bg {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC");
}
.cropper-hide {
  display: block;
  height: 0;
  position: absolute;
  width: 0;
}
.cropper-hidden {
  display: none !important;
}
.cropper-move {
  cursor: move;
}
.cropper-crop {
  cursor: crosshair;
}
.cropper-disabled .cropper-drag-box,
.cropper-disabled .cropper-face,
.cropper-disabled .cropper-line,
.cropper-disabled .cropper-point {
  cursor: not-allowed;
}
</style>
